// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "w_ds";
export var qaBenefitsSection = "w_dg";
export var qaBusinessSection = "w_dh";
export var qaCtaSection = "w_dn";
export var qaCtaSection__cta = "w_dp";
export var qaDomainExpertiseSection = "w_dl";
export var qaProjLogoSection = "w_dr";
export var qaResultSection = "w_dk";
export var qaServicesSection = "w_dc";
export var qaTechStackSection = "w_dm";
export var qaTestimonialPost = "w_dj";
export var qaTestingServicesSection = "w_dd";
export var qaVideoClientSection = "w_dt";
export var qaWhenDoYouNeedSection = "w_df";
export var qaWhyChooseSection = "w_dq";